{
  "alpha": "Alfa",
  "blue": "Modra",
  "brightness": "Svetlost",
  "green": "Zelena",
  "hue": "Barva",
  "lightness": "Lahkost",
  "red": "Rdeča",
  "saturation": "Nasičenost"
}
