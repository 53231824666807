{
  "alpha": "Alpha",
  "blue": "蓝色",
  "brightness": "亮度",
  "green": "绿色",
  "hue": "色相",
  "lightness": "明亮度",
  "red": "红色",
  "saturation": "饱和度"
}
