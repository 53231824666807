{
  "hue": "Hue",
  "saturation": "Saturation",
  "lightness": "Lightness",
  "brightness": "Brightness",
  "red": "Red",
  "green": "Green",
  "blue": "Blue",
  "alpha": "Alpha"
}
