{
  "alpha": "Alfa",
  "blue": "Sinine",
  "brightness": "Heledus",
  "green": "Roheline",
  "hue": "Värv",
  "lightness": "Valgus",
  "red": "Punane",
  "saturation": "Küllastus"
}
