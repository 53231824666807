{
  "alpha": "Alpha",
  "blue": "藍色",
  "brightness": "亮度",
  "green": "綠色",
  "hue": "色相",
  "lightness": "明亮",
  "red": "紅色",
  "saturation": "飽和度"
}
