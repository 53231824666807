{
  "alpha": "Альфа",
  "blue": "Синій",
  "brightness": "Яскравість",
  "green": "Зелений",
  "hue": "Тон",
  "lightness": "Освітленість",
  "red": "Червоний",
  "saturation": "Насиченість"
}
