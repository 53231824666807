{
  "alpha": "Alfa",
  "blue": "Modrá",
  "brightness": "Jas",
  "green": "Zelená",
  "hue": "Odtieň",
  "lightness": "Svetlosť",
  "red": "Červená",
  "saturation": "Sýtosť"
}
