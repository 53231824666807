{
  "alpha": "Alfa",
  "blue": "Mėlyna",
  "brightness": "Ryškumas",
  "green": "Žalia",
  "hue": "Atspalvis",
  "lightness": "Šviesumas",
  "red": "Raudona",
  "saturation": "Įsotinimas"
}
