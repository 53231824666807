{
  "alpha": "Alfa",
  "blue": "Kék",
  "brightness": "Fényesség",
  "green": "Zöld",
  "hue": "Színárnyalat",
  "lightness": "Világosság",
  "red": "Piros",
  "saturation": "Telítettség"
}
