{
  "alpha": "Alfa",
  "blue": "Blå",
  "brightness": "Lysstyrke",
  "green": "Grøn",
  "hue": "Tone",
  "lightness": "Lyshed",
  "red": "Rød",
  "saturation": "Farvemætning"
}
