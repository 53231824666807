{
  "alpha": "Alfa",
  "blue": "Blå",
  "brightness": "Lysstyrke",
  "green": "Grønn",
  "hue": "Fargetone",
  "lightness": "Lyshet",
  "red": "Rød",
  "saturation": "Metning"
}
