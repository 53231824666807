{
  "alpha": "Alfa",
  "blue": "Albastru",
  "brightness": "Luminozitate",
  "green": "Verde",
  "hue": "Nuanță",
  "lightness": "Luminozitate",
  "red": "Roșu",
  "saturation": "Saturație"
}
