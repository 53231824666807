{
  "alpha": "Алфа",
  "blue": "Синьо",
  "brightness": "Яркост",
  "green": "Зелено",
  "hue": "Оттенък",
  "lightness": "Лекота",
  "red": "Червено",
  "saturation": "Наситеност"
}
