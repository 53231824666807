{
  "alpha": "Alpha",
  "blue": "Blau",
  "brightness": "Helligkeit",
  "green": "Grün",
  "hue": "Farbton",
  "lightness": "Leuchtkraft",
  "red": "Rot",
  "saturation": "Sättigung"
}
