{
  "alpha": "Alfa",
  "blue": "Blu",
  "brightness": "Luminosità",
  "green": "Verde",
  "hue": "Tonalità",
  "lightness": "Luminosità",
  "red": "Rosso",
  "saturation": "Saturazione"
}
