{
  "alpha": "Alfa",
  "blue": "Mavi",
  "brightness": "Parlaklık",
  "green": "Yeşil",
  "hue": "Ton",
  "lightness": "Canlılık",
  "red": "Kırmızı",
  "saturation": "Doygunluk"
}
