{
  "alpha": "Альфа",
  "blue": "Синий",
  "brightness": "Яркость",
  "green": "Зеленый",
  "hue": "Оттенок",
  "lightness": "Освещенность",
  "red": "Красный",
  "saturation": "Насыщенность"
}
