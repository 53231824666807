{
  "alpha": "Alfa",
  "blue": "Plava",
  "brightness": "Svjetlina",
  "green": "Zelena",
  "hue": "Nijansa",
  "lightness": "Osvijetljenost",
  "red": "Crvena",
  "saturation": "Zasićenost"
}
