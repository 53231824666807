{
  "alpha": "Alfa",
  "blue": "Modrá",
  "brightness": "Jas",
  "green": "Zelená",
  "hue": "Odstín",
  "lightness": "Světlost",
  "red": "Červená",
  "saturation": "Sytost"
}
