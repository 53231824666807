{
  "alpha": "Άλφα",
  "blue": "Μπλε",
  "brightness": "Φωτεινότητα",
  "green": "Πράσινο",
  "hue": "Τόνος",
  "lightness": "Φωτεινότητα",
  "red": "Κόκκινο",
  "saturation": "Κορεσμός"
}
