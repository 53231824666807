{
  "alpha": "Alpha",
  "blue": "Bleu",
  "brightness": "Luminosité",
  "green": "Vert",
  "hue": "Teinte",
  "lightness": "Luminosité",
  "red": "Rouge",
  "saturation": "Saturation"
}
