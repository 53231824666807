{
  "alpha": "Alfa",
  "blue": "Zila",
  "brightness": "Spilgtums",
  "green": "Zaļa",
  "hue": "Nokrāsa",
  "lightness": "Gaišums",
  "red": "Sarkana",
  "saturation": "Piesātinājums"
}
