{
  "alpha": "알파",
  "blue": "파랑",
  "brightness": "명도",
  "green": "초록",
  "hue": "색조",
  "lightness": "밝기",
  "red": "빨강",
  "saturation": "채도"
}
