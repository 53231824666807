{
  "alpha": "アルファ",
  "blue": "青",
  "brightness": "明るさ",
  "green": "緑",
  "hue": "色相",
  "lightness": "明度",
  "red": "赤",
  "saturation": "彩度"
}
