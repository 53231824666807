{
  "alpha": "Alfa",
  "blue": "Blått",
  "brightness": "Ljusstyrka",
  "green": "Grönt",
  "hue": "Nyans",
  "lightness": "Ljushet",
  "red": "Rött",
  "saturation": "Mättnad"
}
