{
  "alpha": "Alfa",
  "blue": "Niebieski",
  "brightness": "Jasność",
  "green": "Zielony",
  "hue": "Odcień",
  "lightness": "Jaskrawość",
  "red": "Czerwony",
  "saturation": "Nasycenie"
}
