{
  "alpha": "Alfa",
  "blue": "Azul",
  "brightness": "Brilho",
  "green": "Verde",
  "hue": "Matiz",
  "lightness": "Luminosidade",
  "red": "Vermelho",
  "saturation": "Saturação"
}
