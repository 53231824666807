{
  "alpha": "Alpha",
  "blue": "Sininen",
  "brightness": "Kirkkaus",
  "green": "Vihreä",
  "hue": "Sävy",
  "lightness": "Valomäärä",
  "red": "Punainen",
  "saturation": "Värikylläisyys"
}
