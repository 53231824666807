{
  "alpha": "Alfa",
  "blue": "Plava",
  "brightness": "Osvetljenost",
  "green": "Zelena",
  "hue": "Nijansa",
  "lightness": "Osvetljenje",
  "red": "Crvena",
  "saturation": "Zasićenje"
}
