{
  "alpha": "אלפא",
  "blue": "כחול",
  "brightness": "בהירות",
  "green": "ירוק",
  "hue": "גוון",
  "lightness": "כמות אור",
  "red": "אדום",
  "saturation": "רוויה"
}
