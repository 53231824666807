{
  "alpha": "Alfa",
  "blue": "Blauw",
  "brightness": "Helderheid",
  "green": "Groen",
  "hue": "Kleurtoon",
  "lightness": "Lichtsterkte",
  "red": "Rood",
  "saturation": "Verzadiging"
}
