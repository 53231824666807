{
  "alpha": "Alpha",
  "blue": "Azul",
  "brightness": "Brillo",
  "green": "Verde",
  "hue": "Tono",
  "lightness": "Luminosidad",
  "red": "Rojo",
  "saturation": "Saturación"
}
