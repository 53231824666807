{
  "alpha": "ألفا",
  "blue": "أزرق",
  "brightness": "السطوع",
  "green": "أخضر",
  "hue": "درجة اللون",
  "lightness": "الإضاءة",
  "red": "أحمر",
  "saturation": "التشبع"
}
